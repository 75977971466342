export const environment = {
  production: true,
  oktaWidgetConfig: {
    clientId: "0oa1ae7mau4hkhnyU0h8",
    redirectUri: "https://papiadmin-qa.tmhcc.com/callback",
    issuer: "https://hcc-dev.oktapreview.com/oauth2/default",
    scope: ["openid", "email", "profile", "address", "phone", "offline_access"]
  },
  en_key: {
    "_k" : "QrEty34VC2BYUdFge7TeDpM9G0Xtjk8DS0"
  },
  apiConfig: {
    "misc": "U2FsdGVkX1+iLSNiMIBel2gkjhNObb4b3Zj8O0Rw8UjodiSuN19i4RZxqFCREnZn86VQeLc+2KaPJIyw0MnlPwQbFcwkFDN8FucaMWI6tYddpb71p+xMypKtZtSJC58hAkqP6yKWqXYnH045q762Ixfp9+debWgLZ6fBbSSdSkMXq2TfDWgw2bISYlcQk9Z4uz4LcAH+nufU47Sy/uIFbw+uK4E760lUueaJxLUuRrI35nlryGjAkZj/5JqXQQOLYslYLKTJZ1EkkRWYFuQSFhUsgtprOlKJvpICB7lKDVyhMEzaDtqzEkGSS16OERT+kn2wbXXhBaApJcpWP/8rFk9B/UMhX0RC3FaPkiKeI5j8HsRWoV8pERrTY4oWTeXENkjTCYdQoqWQf98YVQSwoXwKZDmbhqycvgpQz8Zhi3hQYeoHgAH8oGPq/uofMIPryRJB4bx6g3zUtjEJwSbAEzOd5LIhWq3qvOLliWNocrRD51YE2Lf60ixEsq0mW5PSkSb24GJ0kRV0pV5l9AJJIl7ZYpEYYe+QuyjspuIy6IljfZUBN0RATPTDVEFvrhqi9G+ylVIFPdnHUyms7Ms80JMJgQ6j/LbHrwdAzBATuWAx1sb2T2Lj76FjuxWUTbYXWlvgPEc6yjpUGnkmVzvo0jeFSxR2XmYTuFMdk7v3tiaQOsMZswoxpIMg1Pxp6TCExGTp3ibiMuLshbOiQRmh82kEKRihbG/HXTKfGZZcz7oA8kdAL1vQbftcecKH6kJnyo6RaPZ9FO/E83LOGaY0BtaqdWTKoNfc2rZyFvQH3BFHEhDyzOBhGPk5Qw7Q6c0IMaxIbk1fNX/z3aU1aPiGlVzqDv5I+iZ6nFJhaI0WKF5LdFI35z9YLb2QgktEqY+q07TuXaJVnI7ld6/75StQdcKy5hrkEHeav7bb81K/V7KXcsaJEJsK0udV2pYJpztXVtiV/yccgg6EfjWE5AYIRMgAZbJe2qYYJj+W/zou3M7SrqfT96nkxICYmKukTuN2UeQ0NJgcRZbN1DrNVwMAH3sn4Z1qNevJk8T5SgUkysaNJrDUgE5AJQgUf0ffQjRdiOFWEy3TlHanUITJt4Vd/a13EiP9aQqY9kOW5i2TBcPfa8tqVvGyqpIcQnZ0YlbdYOyE3q18VDJAZtbsBV1VbrlboUNmnyVrHUGF6LT4vYnnMBZ4iqD2K8TZjOZD3utomw18C0K+keHayrJ4qmdIgQmebeLA4YiqPeXDznlYOv9mMYooaC00KP5CFR8iuu90nmJZ5yHnfWHky+QhMClxK6X5WyRAaH6YOuHgEF1BLEr3RXzjXo5G9toTDSHcQRKb06PaPnKQm5U/GP9Zh4RhVI+lch2NTGeSESGCgTNJEp1+tzF5Rpx/3ocSlCt0azMNGnzgCfRZ/WU4lERLif2vDKLt+yasTDlgi/swzMYo8uxDEuZ9iUC2kTY+/BBzK0UyT3dBXRWhwry2FVfFzJAKyA/+94ABNGUH0+aXNEuKSGpqvsM/oayDp5a+6GsEkoWVyxQTrKEAKYcjI9UgEWgWj4kS4vAhLLizDoTL40xQJh0xzGhMYTgCrToRXNAsjpZAmTDjABfDOfZWi46qVsmyXyVRJGTmtZqaDcr8z+4OPy+b6g1zgiGKnhalnFJg/kqvsFl6xchOdCjS6so799D+CurgT70tkBAe/3HVBiIdS61BLPTJxfH83xyAquN8MRzwec1YGL+DKHNDWeWn0aBuQA=="
  },
  appConfig: {
    "appName": "TMHCC EVENT CANCELLATION",
    "baseHref": "/eco",
    "appKey": "tmhcc-event-cancellation",
    "datePattern": "MM/DD/YYYY",
    "currencyPattern": "",
    "dataServiceBaseUrl": "https://beyondapi-nonprod.tmhcc.com/QA",
    "contentServiceBaseUrl": "",
    "boxUrl": "https://hcdevbox.azurewebsites.net",
    "smartStreetAPIKey":"29050599823958456",
    "logging": {
      "console": true,
      "appInsightEnabled": false,
      "appInsights": {
        "instrumentationKey": "9a51af73-2d5e-4414-8084-94717542b129",
        "enableAutoRouteTracking": false
      }
    }
  }
};
